/**
 * External dependencies
 */
import React from "react";
import classNames from "classnames";
import {Link}  from "gatsby"

/**
 * Internal dependencies
 */
import "./style/_index.scss";

const Button = ({ className, children, hideArrow, small, link, type, mailIcon, callIcon, clickHandler }) => {
  return (
    <button type={type} className={classNames(`btn`, className, { "is-smaller": small })} onClick={clickHandler}>
      {link ?      
      <Link to ={link} className="linkbtn">
      <div>{children}</div>
      {mailIcon && <i className="icon-m-email"></i>}
      {!hideArrow && <i className="icon-arrow-right"></i>}
      
      </Link>
      :      
      <>
      <div>{children}</div>
      {!hideArrow && <i className="icon-arrow-right"></i>}
      {mailIcon && <i className="icon-m-email"></i>}
      {callIcon && <i className="icon-ph-call"></i>}
      </>
      }
    </button>
  )
}

export default Button;

/**
 * External dependencies
 */
import React, { useContext } from "react";
import { useAccordionButton, AccordionContext } from "react-bootstrap";

/**
 * Internal dependencies
 */
import "./styles/_index.scss";

const AccordionToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(eventKey, () => '')

  return (
    <button type="button" onClick={decoratedOnClick} className="custom-toggle">
      {children}
      <i
        className={activeEventKey === eventKey ? "icon-minus" : "icon-plus"}
      ></i>
    </button>
  )
}

export default AccordionToggle;

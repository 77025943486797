/**
 * External dependencies
 */
import React, {useEffect, useState} from "react"
import { Col, Nav, Navbar, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery";
import PropertySearchOverlay from "./property-search-overlay";
import SearchOverlay from "./SearchOverlay"

/**
 * Internal dependencies
 */
import "./style/_index.scss"

const Header = (props) => {
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      allMenus(sort:"Sorting:asc") {
        id
        Label
        Primary_URL
        Secondary_URL
        Show_In_Top_Nav
        Main_Parent {
          id
          Label
          Primary_URL
          Secondary_URL
        }
        Sub_Parent {
          id
          Label
          Primary_URL
          Secondary_URL            
        }
      }
    }
  }
`)

  const [scroll, setScroll] = useState(false);


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if(props.bannerimage === "transparent-header") {
        setScroll(window.scrollY > 30);
      } else {
        setScroll(window.scrollY > 120);
      }
    });

    $(".main-nav .nav-dropdown ul").each(function(i, el) {
      // Remove white space
      var html = $(el).html().replace(/\s+/, "");
      // Check if element is :empty or length of html is zero
      if ($(el).is(":empty") || html.length == 0){
          $(el).remove();
      } else {
        $(el).parent().addClass('has-submenu');          
        $(el).parent().parent().addClass('has-submenu');          
      }
  });
  })
  const handleToggleDropdonwMobile = e => {
    const ele = e.target
    const dropDown = ele.parentNode.querySelector(".nav-dropdown")
    document.querySelectorAll(".nav-dropdown").forEach(item => {
      if (item !== dropDown) {
        item.classList.remove("is-active")
      }
    })
    document.querySelectorAll(".nav-dropdown-toggle").forEach(item => {
      if (item !== ele) {
        item.classList.remove("is-active")
      }
    })
    ele.classList.toggle("is-active")
    ele.parentNode.querySelector(".nav-dropdown").classList.toggle("is-active")
  }

  var menu_data = data.glstrapi.allMenus;

  // Search overlay
  function searchOverlay(e) {
    $("body")
        // .addClass("modal-open")
        // .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }
  // Search overlay

  let isHeaderTranparent = (props.bannerimage && props.pagename !== "Valuation") || (props.pagename === "Home")

  return (
    <header className={isHeaderTranparent ?'header transparent-header':'header'} id={ isHeaderTranparent && scroll ?'transparent--fixed':""}>
      <div className="d-xl-none">
        <SearchOverlay />
      </div>
      <div className="shell">
        <div className="header__inner">
          <Navbar expand="lg">
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <Link to="/" className="navbar-brand">
                  <i className="logo"></i>
                </Link>
              </Col>
              <Col xs="auto">
                <Row className="align-items-center header__utils">
                  <Col xs="auto">
                    <a href="tel:+441795293000">
                      <i className="icon-phone"></i>
                    </a>
                  </Col>
                  <Col xs="auto">
                    <a href="javascript:void(0)" onClick={searchOverlay}>
                      <i className="icon-search"></i>
                    </a>
                  </Col>
                  <Col xs="auto">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  </Col>
                </Row>

                <Navbar.Collapse id="basic-navbar-nav" className="align-items-start">
                  <Nav className="me-auto main-nav">
                    <Row className="justify-content-end align-items-center header__utils">
                      <Col xs="auto">
                        <a href="tel:20293000">
                          <i className="icon-phone"></i>
                        </a>
                      </Col>
                      <Col xs="auto">
                        <a href="javascript:void(0)" onClick={searchOverlay}>
                          <i className="icon-search"></i>
                        </a>
                      </Col>
                      <Col xs="auto">
                        <Navbar.Toggle
                          aria-controls="basic-navbar-nav"
                          className="close"
                        />
                      </Col>
                    </Row>
                    <ul>
                      <li className="property-search has-submenu">
                        <Link to="/property/for-sale/in-sittingbourne/" className="demi">
                          <i className="icon-search-bold"></i>
                          Property Search
                        </Link>
                        <div className="nav-dropdown property-overlay-dropdown">
                          <PropertySearchOverlay />
                        </div>
                      </li>
                      <li>
                        <Link to="/property-valuation/" className="demi">
                          <i className="icon-house"></i>
                          Valuation
                        </Link>
                      </li>
                      {menu_data.map((menuitem,key) => {
                      return<>
                      {menuitem.Show_In_Top_Nav === true &&
                      <li>
                        <Link to={`/${menuitem.Primary_URL}/`}>{menuitem.Label}</Link>

                        <button
                          className="nav-dropdown-toggle"
                          onClick={handleToggleDropdonwMobile}
                        ></button>
                        <div className="nav-dropdown">
                          <ul>
                          {menu_data.map((submenu) => {
                            return<>
                            {submenu.Main_Parent && submenu.Main_Parent.Primary_URL === menuitem.Primary_URL &&
                            <li>
                              {(submenu.Secondary_URL)?
                                <Link to={submenu.Secondary_URL}>{submenu.Label}</Link>
                              :
                                <Link to={`/${submenu.Main_Parent.Primary_URL}/${submenu.Primary_URL}/`}>{submenu.Label}</Link>
                              }
                            </li>
                            }                           
                            </>
                          })}
                          </ul>
                        </div>
                      </li>
                      }
                      </>
                      })}
                      {/* <li>
                        <Link to="/sell">Sell</Link>
                        <button
                          className="nav-dropdown-toggle"
                          onClick={handleToggleDropdonwMobile}
                        ></button>
                        <div className="nav-dropdown">
                          <ul>
                            <li>
                              <Link to="/">Property Valuation</Link>
                            </li>
                            <li>
                              <Link to="/">Property Management</Link>
                            </li>
                            <li>
                              <Link to="/">Why Let with us?</Link>
                            </li>
                            <li>
                              <Link to="/">Landlords Advice</Link>
                            </li>
                            <li>
                              <Link to="/">Landlords Fees</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/rent">Rent</Link>
                        <button
                          className="nav-dropdown-toggle"
                          onClick={handleToggleDropdonwMobile}
                        ></button>
                        <div className="nav-dropdown">
                          <ul>
                            <li>
                              <Link to="/">Property Valuation</Link>
                            </li>
                            <li>
                              <Link to="/">Property Management</Link>
                            </li>
                            <li>
                              <Link to="/">Why Let with us?</Link>
                            </li>
                            <li>
                              <Link to="/">Landlords Advice</Link>
                            </li>
                            <li>
                              <Link to="/">Landlords Fees</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/landlords">Landlords</Link>
                        <button
                          className="nav-dropdown-toggle"
                          onClick={handleToggleDropdonwMobile}
                        ></button>
                        <div className="nav-dropdown">
                          <ul>
                            <li>
                              <Link to="/">Property Valuation</Link>
                            </li>
                            <li>
                              <Link to="/">Property Management</Link>
                            </li>
                            <li>
                              <Link to="/">Why Let with us?</Link>
                            </li>
                            <li>
                              <Link to="/">Landlords Advice</Link>
                            </li>
                            <li>
                              <Link to="/">Landlords Fees</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li> */}
                    </ul>
                  </Nav>
                </Navbar.Collapse>
              </Col>
            </Row>
          </Navbar>
        </div>
      </div>
    </header>
  )
}

export default Header

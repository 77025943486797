import React, {useEffect, useState} from "react"
import { Col, Nav, Navbar, Row } from "react-bootstrap"
import { Link } from "gatsby"
import $ from "jquery";
import { navigate } from "@reach/router"
import SearchResultBoxMenu from "../property-search-bar/SearchResultBoxMenu"
import "./style/_property-search-overlay.scss"

const SearchOverlay = (props) => {

    // Close overlay
    const closeSearchOverlay = (e) => {
        // $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }
    // Close overlay

    // Buy & Sell
    const submitBuy = (e) => {
        var areaValue = $(".location-search-popup input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if(areaValue) {
            searchFilterResults = "/property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/property/for-sale/in-sittingbourne/";
        }

        navigate(searchFilterResults);
    }

    const submitSell = (e) => {
        var areaValue = $(".location-search-popup input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if(areaValue) {
            searchFilterResults = "/property/to-rent/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/property/to-rent/in-sittingbourne/";
        }

        navigate(searchFilterResults);
    }
    // Buy & Sell

    return (
        <div className="search-overlay">
            <a href="javascript:void(0)" onClick={closeSearchOverlay}><i className="icon-search-close"></i></a>

            <div className="search-overlay-popup">
                <div className="location-search location-search-menu location-search-popup mb-5">
                    <SearchResultBoxMenu placeHolder={`Location`} />
                </div>
                <div className="d-flex justify-content-between search-overlay-btn">
                    <button className="d-flex btn btn--outline-white w-md-auto property-search-btn property-overlay-btn me-3" onClick={submitBuy}><div className="search-text">Buy</div><i className="icon-arrow-right"></i></button>
                    <button className="d-flex btn btn--outline-white w-md-auto property-search-btn property-overlay-btn" onClick={submitSell}><div className="search-text">Rent</div><i className="icon-arrow-right"></i></button>
                </div>
            </div>
        </div>
    )
}

export default SearchOverlay;
import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const GetUrl = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(publicationState: LIVE) {
          id
          Label
          Primary_URL
          Secondary_URL
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)

  const PageURL = data.glstrapi?.allMenus
    .filter(item => item?.Primary_URL === props?.link)
    .pop()

  if (!PageURL) return null

  let Primary_URL =
    PageURL.Main_Parent === null &&
    PageURL.Sub_Parent === null &&
    props.link === PageURL.Primary_URL &&
    !props.customlink
      ? PageURL.Primary_URL
      : false

  let Secondary_URL =
    props.customlink === PageURL.Secondary_URL ? PageURL.Secondary_URL : false

  return (
    <>
      {Primary_URL && (
        <span>
          <Link to={`/${Primary_URL}/`}>{props.label}</Link>
        </span>
      )}
      {Secondary_URL && (
        <span>
          <a href={Secondary_URL} target="_blank">{props.label}</a>
        </span>
      )}
    </>
  )
}

export default GetUrl

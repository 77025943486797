import React, {useEffect, useState} from "react"
import { Col, Nav, Navbar, Row } from "react-bootstrap"
import { Link } from "gatsby"
import $ from "jquery";
import { navigate } from "@reach/router"
import SearchResultBoxMenu from "../property-search-bar/SearchResultBoxMenu"
import "./style/_property-search-overlay.scss"

const PropertySearchOverlay = (props) => {

    // Buy & Sell
    const submitBuy = (e) => {
        var areaValue = $(".location-search-overlay-menu input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if(areaValue) {
            searchFilterResults = "/property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/property/for-sale/in-sittingbourne/";
        }

        navigate(searchFilterResults);
    }

    const submitSell = (e) => {
        var areaValue = $(".location-search-overlay-menu input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if(areaValue) {
            searchFilterResults = "/property/to-rent/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/property/to-rent/in-sittingbourne/";
        }

        navigate(searchFilterResults);
    }
    // Buy & Sell

    return (
        <div className="property-search-overlay d-lg-block d-md-none">
            <div className="overlay--title">Find your perfect home</div>
            <p className="mb-4">Whether you’re looking for a serviced apartment to rent or a new home for your family, we have a wide range of properties in Sittingbourne to suit your every need.</p>
            <div className="location-search location-search-menu location-search-overlay-menu mb-5">
                <SearchResultBoxMenu placeHolder={`Location`} />
            </div>
            <div className="d-flex justify-content-between search-overlay-btn">
                <button className="d-flex btn btn--fill-lightblue w-md-auto property-search-btn me-3" onClick={submitBuy}><div className="search-text">Buy</div><i className="icon-arrow-right"></i></button>
                <button className="d-flex btn btn--fill-lightblue w-md-auto property-search-btn" onClick={submitSell}><div className="search-text">Rent</div><i className="icon-arrow-right"></i></button>
            </div>
        </div>
    )
}

export default PropertySearchOverlay;
import React from 'react'
const AltSiteName = " - LambornHill"
const ContactURL  = '/contact'
const NewsBaseURL = '/about/property-blogs'
const TeamURL = '/about/our-team'
const AreaguidesURL = '/about/areas-we-cover'
const PropertValuationURL = "/property-valuation"

export {
    AltSiteName,
    ContactURL,
    NewsBaseURL,
    TeamURL,
    AreaguidesURL,
    PropertValuationURL
}

/**
 * External dependencies
 */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useLocation } from "@reach/router"
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css"

/**
 * Internal dependencies
 */
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import "../styles/main.scss"
import "aos/dist/aos.css"

const Layout = (props, { children }) => {
  const location = useLocation()
  const formatClass =
    location.pathname === "/"
      ? "index"
      : location.pathname.replace(/[^a-zA-Z]/g, "")

  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  var layoutclass=""
  if(props.layout) {
    layoutclass = props.layout.toLowerCase();
  } else {
    layoutclass = ""
  }
  return (
    <div className={classNames("wrapper", `page-${formatClass}`+` bodyclass-${props.pageclass} ${layoutclass}`)}>
      <Header bannerimage={props.bannerimage} pagename={props.pagename}/>
      {props.children}
      <Footer mapFooter={props.tag} pageclass={props.pageclass} popularsearch={props.popularsearch}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

/**
 * External dependencies
 */
import React, {useEffect} from "react"
import { Row, Col, Accordion, Card } from "react-bootstrap"
import { Link } from "gatsby"
import HTMLReactParser from "html-react-parser"
import AccordionToggle from "../accordion-toggle/accordion-toggle"
import { useStaticQuery, graphql } from "gatsby"
import { ContactURL } from "../utils"
import GetURL from "../getUrl"
import { isMacOs } from "react-device-detect"
import $ from "jquery"
/**
 * Internal dependencies
 */
import Button from "../button/button"
import "./styles/_index.scss"
import classNames from "classnames"

const Footer = (props) => {
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        Facebook_Link
        Instagram_Link
        Twitter_Link
        Youtube_Link
        Footer_Phone
        Footer_Email
        Get_In_Touch {
          Address
        }
        Footer_Links {
          Link_Label
          Menu_Link {
            Primary_URL
            Secondary_URL
          }
        }
        Clients {
          Client_Logo {
            url
          }
          Client_Url
          Class_Name
          alt
        }
      }
    }
  }
  `)
  var fetch_data = data.glstrapi.globalConfig;
  var date = new Date();

  useEffect(() => {
   $('.property-search-overlay .property-search-btn').click(function(){
      $(window).scrollTop(0);
   }); 
   $('.footer .accordion-props__body li a').click(function(){
    $(window).scrollTop(0);
   });
   $('.home-intro__actions a').click(function(){
      $(window).scrollTop(0);
   }); 
     
  });
  return (
    <footer className={ props.mapFooter === "mapView" ? "footer mt-0" : classNames("footer", `${props.pageclass === "area-guide-landing" && "footer_area-guide"}`)}>
      <div className="shell">
        <Row className="justify-content-between">
          <Col xs="12" md="6" className="footer__text">
            <h1>Every property journey starts with a conversation.</h1>
            <p>
              Our agents are all local experts who live where we work and are ready to help you every step of the way.
            </p>
            <div className="footer__actions">
              <Button link={ContactURL} className="btn--outline-white">Get in touch</Button>
            </div>
          </Col>

          <Col xs="12" md="6">
            <nav className="footer__socials">
              <ul>
                <li>
                  <a href={fetch_data.Facebook_Link} target="_blank">
                    <i className="icon-facebook"></i>
                  </a>
                </li>
                <li>
                <a href={fetch_data.Instagram_Link} target="_blank">
                    <i className="icon-instagram"></i>
                  </a>
                </li>
                <li>
                <a href={fetch_data.Twitter_Link} target="_blank">
                    <i className="icon-twitter"></i>
                  </a>
                </li>
                <li>
                <a href={fetch_data.Youtube_Link} target="_blank">
                    <i className="icon-youtube"></i>
                  </a>
                </li>
              </ul>
            </nav>

            <div className="footer__contacts">
              <a href={`tel:${fetch_data.Footer_Phone}`}>{fetch_data.Footer_Phone}</a>
              <Link to={ContactURL}>
                {fetch_data.Footer_Email}
              </Link>
            </div>
          </Col>
        </Row>
        <Accordion className="accordion-props">
          <AccordionToggle eventKey="0">Popular Searches</AccordionToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="accordion-props__body">
              <nav className="nav-props">
              {props.popularsearch != "Lettings" &&

                <ul>
                 <li>
                    <Link to="/property/for-sale/in-kent/">Properties for sale in Kent</Link>
                  </li>
                  <li>
                    <Link to="/property/for-sale/in-sittingbourne/">Properties for sale in Sittingbourne</Link>
                  </li>
                 
                  <li>
                    <Link to="/property/for-sale/in-isle-of-sheppey/">Properties for sale in Isle of Sheppey</Link>
                  </li>
                  <li>
                    <Link to="/property-valuation/">Property Valuation in Kent</Link>
                  </li>
                  
                </ul>
                }
                {props.popularsearch != "Sales" &&
                <ul>
                  <li>
                    <Link to="/property/to-rent/in-kent/">Properties to rent in Kent</Link>
                  </li>
                  <li>
                    <Link to="/property/to-rent/in-sittingbourne/">Properties to rent in Sittingbourne</Link>
                  </li>
                  <li>
                    <Link to="/property/to-rent/in-isle-of-sheppey/">Properties to rent in Isle of Sheppey</Link>
                  </li>
                  <li>
                    <Link to="/landlords/property-management/">Property management in Kent</Link>
                  </li>
                </ul>
                }
                {props.popularsearch === "Sales" &&
                <ul>
                 <li>
                    <Link to="/property/for-sale/in-sittingbourne/type-bungalow/">Bungalows for sale in Sittingbourne</Link>
                  </li>
                  <li>
                    <Link to="/property/for-sale/in-sittingbourne/type-flat/">Flats for sale in Sittingbourne</Link>
                  </li>
                 
                  <li>
                    <Link to="/property/for-sale/in-sittingbourne/type-house/">Houses for sale in Sittingbourne</Link>
                  </li>
                  <li>
                  <Link to="/landlords/property-management/">Property management in Kent</Link>
                  </li>                  
                </ul>
                }
                {props.popularsearch === "Lettings" &&
                <ul>
                 <li>
                    <Link to="/property/to-rent/in-sittingbourne/type-bungalow/">Bungalows to rent in Sittingbourne</Link>
                  </li>
                  <li>
                    <Link to="/property/to-rent/in-sittingbourne/type-flat/">Flats to rent in Sittingbourne</Link>
                  </li>
                 
                  <li>
                    <Link to="/property/to-rent/in-sittingbourne/type-house/">Houses to rent in Sittingbourne</Link>
                  </li>
                  <li>
                  <Link to="/landlords/property-management/">Property management in Kent</Link>
                  </li>                  
                </ul>
                }
                
                <ul>
                  <li>
                    <Link to="/contact/">Estate agents in Sittingbourne</Link>
                  </li>
                  <li>
                    <Link to="/about/">About LambornHill</Link>
                  </li>
                  
                  {(props.popularsearch === "Sales") || (props.popularsearch === "Lettings") ?
                  <li>
                    <Link to="/about/our-team/">Meet our team</Link>
                  </li>
                  :
                  <li>
                    <Link to="/sell/">Sell your property in Kent</Link>
                  </li>
                  }
                  <li>
                    <Link to="/buy/mortgages/">Mortgage advisors in Kent</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/about/areas-we-cover/sittingbourne/">
                    Sittingbourne area guide
                    </Link>
                  </li>
                  <li>
                    <Link to="/about/areas-we-cover/isle-of-sheppey/">Isle of Sheppey area guide</Link>
                  </li>
                  <li>
                    <Link to="/about/property-blogs/">
                    Latest property news
                    </Link>
                  </li>
                  <li>
                    <Link to="/about/customer-reviews/">Customer testimonials</Link>
                  </li>
                </ul>
              </nav>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>

        <Row className="footer__bottom">
          <Col lg="6" className="copyright">
            {fetch_data.Footer_Links.length > 0 &&
              <p className="policies">
                {fetch_data.Footer_Links.map(item => (
                  <GetURL label={item?.Link_Label} link={item?.Menu_Link?.Primary_URL} customlink={item?.Menu_Link?.Secondary_URL}/>
                ))}
              </p>
            }
            <p>{date.getFullYear()} &copy; LambornHill. All rights reserved.</p>
            {fetch_data.Get_In_Touch.Address && HTMLReactParser(fetch_data.Get_In_Touch.Address)}
            <p className="site-by">Site by&nbsp;<Link to="https://starberry.tv/"><i className="starberry-logo"></i>&nbsp;Starberry</Link></p>
          </Col>
          {fetch_data.Clients.length > 0 &&
            <Col lg="6" className="static-logos">
              <ul>
                {fetch_data.Clients.map((c) => (
                  <li>
                    <a href={c.Client_Url} target="_blank">
                      <img loading="lazy" className={c.Class_Name} src={c.Client_Logo.url} alt={c.alt}/>
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          }
        </Row>
      </div>
    </footer>
  )
}

export default Footer
